import {
    Abstract
} from './Abstract';

import axios from 'axios';
export class TMTicket extends Abstract {
    constructor(session) {
        super('tm/Ticket', session);
        this.subject = null;
        this.body = null;
        this.asignee_tm_user = null;
        this.id_tm_ticket_tag = null;
        this.id_tm_customer = null;
        this.id_tm_project = null;
        this.status = null;
        this.expiration_date = null;
        this.is_closed = false;
        this.feedback = null;
        this.closed_date = null;
        this.closed_by = null;
    }

    async enviarCorreoNuevo() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        let response = await axios.post(this.controller + "/mail/new/" + this.id, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async enviarCorreoCerrado() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        let response = await axios.post(this.controller + "/mail/closed/" + this.id, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async cerrar() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        let response = await axios.put(this.controller + "/close/" + this.id, this, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async abrir() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        let response = await axios.put(this.controller + "/open/" + this.id, this, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async updateStatus() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        let response = await axios.put(this.controller + "/status/" + this.id, this, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async graph(version, data) {
        let response = await axios.post(this.controller + "/graph/" + version, data);
        return response.data;
    }
}



