<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Ticket">
          <Toolbar v-if="!view" class="p-mb-4">
            <template v-slot:left>
              <Button
                label="Nuevo"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                @click="openNew"
              />
              <Button
                label="Guardar"
                icon="pi pi-save"
                class="p-button-info p-mr-2"
                @click="save"
              />
              <Button
                label="Recargar"
                icon="pi pi-refresh"
                class="p-button-primary p-mr-2"
                @click="refresh"
              />
              <Button
                label="Enviar Correo"
                icon="pi pi-envelope"
                class="p-button-warning p-mr-2"
                @click="sendEmail"
              />
            </template>
          </Toolbar>
          <Fieldset :toggleable="true" legend="Informacion General">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label>Titulo</label>
                <InputText
                  v-model="entity.subject"
                  :class="{ 'p-invalid': validate.validations.subject === false }"
                />
                <small class="p-invalid" v-if="validate.validations.subject === false">
                  Favor de llenar el campo
                </small>
              </div>
              <div class="p-field p-col-12">
                <label for="sat">Contenido</label>
                <Textarea
                  v-model="entity.body"
                  :autoResize="false"
                  :rows="8"
                  :class="{ 'p-invalid': validate.validations.body === false }"
                />
                <small class="p-invalid" v-if="validate.validations.body === false">
                  Favor de llenar el campo
                </small>
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Cliente</label>
                <Dropdown
                  v-model="entity.id_tm_customer"
                  :loading="loadingInputs"
                  :options="customers"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Proyecto</label>
                <Dropdown
                  v-model="entity.id_tm_project"
                  :options="projectsByCustomer"
                  :disabled="loadingInputs"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>

              <div class="p-field p-col-3">
                <label for="sat">Asginado a</label>
                <Dropdown
                  v-model="entity.asignee_tm_user"
                  :options="users"
                  optionLabel="name"
                  optionValue="id"
                  :class="{
                    'p-invalid': validate.validations.asignee_tm_user === false,
                  }"
                />
                <small
                  class="p-invalid"
                  v-if="validate.validations.asignee_tm_user === false"
                >
                  Favor de llenar el campo
                </small>
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Tag</label>
                <Dropdown
                  v-model="entity.id_tm_ticket_tag"
                  :options="tags"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Estatus</label>
                <Dropdown
                  v-model="entity.status"
                  :options="[
                    {
                      value: 1,
                      name: 'REGISTRADO',
                    },
                    {
                      value: 2,
                      name: 'EN PROCESO',
                    },
                    {
                      value: 3,
                      name: 'TERMINADO',
                    },
                    {
                      value: 9,
                      name: 'CANCELADO',
                    },
                  ]"
                  optionLabel="name"
                  optionValue="value"
                  :class="{ 'p-invalid': validate.validations.status === false }"
                />
                <small class="p-invalid" v-if="validate.validations.status === false">
                  Favor de llenar el campo
                </small>
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Fecha Entrega</label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  v-model="entity.expiration_date"
                  :showIcon="true"
                />
              </div>
            </div>
          </Fieldset>
          <br />
          <Fieldset :toggleable="true" legend="Informacion Adicional">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-4">
                <label>Estatus Adicional</label><br />
                <label v-if="entity.is_closed">Cerrado</label>
                <label v-else>Abierto</label>
              </div>
              <div class="p-field p-col-4">
                <label>Fecha Cierre</label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  :disabled="true"
                  v-model="entity.closed_date"
                  :showIcon="true"
                />
              </div>
              <div class="p-field p-col-4">
                <label>Usuario Cierre</label>
                <InputText v-model="entity.closed_by" :readonly="true" />
              </div>
              <div class="p-field p-col-12">
                <label>Retroalimentacion</label>
                <Textarea
                  v-model="entity.feedback"
                  :autoResize="false"
                  :rows="8"
                  :readonly="true"
                />
              </div>
            </div>
          </Fieldset>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { TMTicketTag } from "../../../models/tickets/TMTicketTag";
import { TMUser } from "../../../models/tickets/TMUser";
import { TMProject } from "../../../models/tickets/TMProject";
import { TMTicket } from "../../../models/tickets/TMTicket";

import { TMCustomer } from "../../../models/tickets/TMCustomer";
import { FilterMatchMode } from "primevue/api";
import {
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";

export default {
  mixins: [Session],
  props: {
    id: null,
    view: null,
  },
  data() {
    return {
      entity: null,
      entities: [],
      users: [],
      tags: [],
      projects: [],
      projectsByCustomer: [],
      customers: [],
      filters: {},
      rules: [
        new Rule({ name: "subject" }),
        new Rule({ name: "body" }),
        new Rule({ name: "asignee_tm_user" }),
        new Rule({ name: "status" }),
      ],
      validate: {
        valid: false,
        validations: {
          subject: null,
          body: null,
          asignee_tm_user: null,
          status: null,
        },
      },
      loading: false,
      loadingInputs: false
    };
  },
  components: { Loader },
  watch: {
    async ["entity.id_tm_customer"](newValue) {
      if (newValue) {
        try {
          this.loadingInputs = true;
          this.projectsByCustomer = this.projects.filter(project => project.id_tm_customer == newValue)
          if (!this.projectsByCustomer.find(project => project.id == this.entity.id_tm_project)) {
            this.entity.id_tm_project = null;
          }
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loadingInputs = false;
        }
      }
    },
  },
  created() {
    this.entity = new TMTicket(this.session);
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    openNew() {
      this.entity = new TMTicket(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        if (this.entity.id && this.entity.id > 0) {
          let updatedEntity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });

          //Agregamos valores para regresar la entidad
          updatedEntity.customer_name = this.customers.find(
            (x) => x.id == updatedEntity.id_tm_customer
          ).name;
          updatedEntity.project_name = this.projects.find(
            (x) => x.id == updatedEntity.id_tm_project
          ).name;
          updatedEntity.ticket_tag_name = this.tags.find(
            (x) => x.id == updatedEntity.id_tm_ticket_tag
          ).name;
          updatedEntity.user_name = this.users.find(
            (x) => x.id == updatedEntity.asignee_tm_user
          ).name;
          this.$emit("updated", updatedEntity);
        } else {
          let newEntity = await this.entity.save();
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
          this.$emit("saved", newEntity);
        }
        this.entity = new TMTicket(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async sendEmail() {
      try {
        this.loading = true;
        if (this.entity.is_closed) {
          throw "No puede enviar un correo de un ticket cerrado";
        }
        await this.entity.enviarCorreoNuevo();
        this.$toast.add({
          severity: "success",
          summary: "Correo",
          detail: "Se envio el correo del ticket con exito",
          life: 3000,
        });
        this.$emit("deleted");
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new TMTicket(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
        this.$emit("deleted");
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async refresh() {
      this.loading = true;
      try {
        this.tags = await new TMTicketTag().all();
        this.users = await new TMUser().all();
        this.customers = await new TMCustomer().all();
        this.projects = await new TMProject().all();
        //Si tiene un id lo cargamos
        if (this.id) {
          this.entity.id = this.id;
          let retrieved = await this.entity.retrieve();
          fillObject(this.entity, retrieved);
          //this.entity.id_tm_project = retrieved.id_tm_project;
          if (this.entity.expiration_date)
            this.entity.expiration_date = new Date(this.entity.expiration_date);

          if (this.entity.closed_date)
            this.entity.closed_date = new Date(this.entity.closed_date);
        } 
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
